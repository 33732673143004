import React from 'react';
import queryString from 'query-string';
import {PersonalLoanSoftQuoteBroker} from '@/components/PersonalLoanSoftQuote/pages-broker/PersonalLoanSoftQuoteBroker';
import Metadata from '@/components/Metadata/Metadata';
import { SOFTQUOTE_METADATA } from '@/components/Metadata/Metadata.constants';
import {DatadogAppender} from '@/components/PersonalLoanSoftQuote/DatadogAppender';
import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';

const Index = (props) => {
  // if broker has bookmarked a softquote URL with a better start query string parameter, redirect them to the debt con page when the better start promo is not running
  const queryParams = typeof window !== 'undefined' && queryString.parse(window.location.search);
  if (queryParams && queryParams.subType === `betterStart`) {
    const plDebtConAuUrl = getUrlFromEnv('pl-debt-con-au-url');
    if (plDebtConAuUrl) window.location = plDebtConAuUrl;
  }
  return (
    <>
      <Metadata {...SOFTQUOTE_METADATA} />
      <DatadogAppender/>
      <PersonalLoanSoftQuoteBroker props = {props}/>
    </>
  );
};
export default Index;
