import styled from '@emotion/styled';

const EmploymentWhiteCheckboxWrapper = styled.div`
    div[role='checkbox'] {
        label {
            display: inherit;
            margin-bottom: 0 !important;
        }
        a {
            color: inherit !important;
            text-decoration: underline !important;
            border-bottom: none !important;
            &:hover {
                text-decoration: underline !important;
                color: unset !important;
                text-shadow: none !important;
                border-bottom: none !important;
            }
        }
    }
`;

export default EmploymentWhiteCheckboxWrapper;